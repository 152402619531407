
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}

// For the extended-teams Deverything <3 Mexico mobile
@media (max-width:767px) {
  .display-3 {
    font-size: 2.5rem;
  }
}

@media (max-width: 767px) {
  .h4, h4 {
	font-size: 1.314rem;
  }
}
//end extended-teams